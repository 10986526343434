<template>
  <v-responsive content-class="d-flex align-center justify-center">
    <!-- TODO: cleanup this stuff -->
    <v-card dark max-height="90vh" class="overflow-hidden">
      <v-form @submit.stop="createUser" v-model="valid" lazy-validation :disabled="loading" :loading="loading">
        <v-container>
          <v-row>
            <v-col cols="6">
              <v-select dense :items="subscriptions" v-model="targetSubscription" label="Sub lvl" clearable></v-select>
            </v-col>
            <v-col cols="6" class="d-flex">
              <v-text-field dense readonly :value="emailPrefix"></v-text-field>
              <span>+</span>
              <v-text-field type="number" dense v-model.number="emailSuffix"></v-text-field>
              <span>@</span>
              <v-text-field dense v-model="emailDomain"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <pre><code>{{ createUserParams }}</code></pre>
            </v-col>
            <v-col cols="6" class="scrolled-max-h" style="max-height: 20em;">
              <pre v-if="createUserOutput"><code>{{ createUserOutput }}</code></pre>
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn type="submit" color="primary" :loading="loading" :disabled="!valid || loading">Create user</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-responsive>
</template>

<script>
import moment from 'moment';
import { Client } from '@/services';
import RecaptchaForm from '../../../components/mixins/RecaptchaForm';
import { Services } from '../../../store/actions/api';
import { jsonToFormData } from '../../../utils/http-common';
import { levels } from '../../service-plan/tools/enums';
import { captureException } from '@sentry/vue';

export default {
  name: 'UserGenerator',

  mixins: [RecaptchaForm],

  data() {
    return {
      loading: false,
      valid: true,
      emailSuffix: 0,
      emailDomain: 'flightscope.dev',

      subscriptions: Object.values(levels),
      createUserOutput: null,

      targetSubscription: null,
    };
  },

  computed: {
    emailPrefix() {
      return `e-${(this.targetSubscription?.toLowerCase() ?? 'nonlevel')}+${moment().format('YYYYMMDDHHmmss')}`;
    },
    createUserParams() {
      const params = {
        // String $Email, String $DisplayName = '', String $Password = '', Int $GenderID = 1
        // Int $IsPublic = 0, String $ParametersJSON = '', Int $AutoLogin = 0, Int $Strict = 0,
        // DBi $dbi = null, ConfirmationTracker & $ct = null, $returnAsArray = false )

        Email: `${this.emailPrefix.toLowerCase()}+${this.emailSuffix}@${this.emailDomain}`,
        DisplayName: `${this.emailPrefix} VERSION-${this.emailSuffix}`,
        GenderID: 1,
        Password: 'test123',
        Strict: 1,
      };
      if (this.targetSubscription) {
        params.ParametersJSON = {
          targetSubscription: this.targetSubscription,
        };
      }
      return params;
    },
  },

  methods: {
    // TODO: remove later
    async createUser(e) {
      e.preventDefault();
      this.loading = true;

      const params = {
        service: Services.FlightScopeUser.key,
        method: Services.FlightScopeUser.methods.InsertUser.key,
      };

      const rcToken = await this.recaptchaToken('register_devtools');

      const payload = {
        ...this.createUserParams,
      };

      if (this.enableRecaptcha) {
        payload.RC_ResponseToken = rcToken;
      }

      const data = jsonToFormData(payload);

      try {
        this.createUserOutput = null;
        const response = await Client({ data, params, method: 'post' });
        this.createUserOutput = response;
      } catch (error) {
        captureException(error);
        this.createUserOutput = error;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.scrolled-max-h {
  --max-h: calc(90vh - 64px - 52px);
}
</style>
